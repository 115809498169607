<template>
  <div class="create-instagram-ad">
    <h2>Create Instagram Ad</h2>

    <!-- Create Campaign -->
    <form @submit.prevent="createCampaign">
      <h3>Create Campaign</h3>
      <div>
        <label for="campaignName">Campaign Name:</label>
        <input type="text" id="campaignName" v-model="campaignName" required />
      </div>
      <button type="submit">Create Campaign</button>
    </form>

    <!-- Create Ad Set -->
    <form @submit.prevent="createAdSet" v-if="campaignId">
      <h3>Create Ad Set</h3>
      <div>
        <label for="adSetName">Ad Set Name:</label>
        <input type="text" id="adSetName" v-model="adSetName" required />
      </div>
      <button type="submit">Create Ad Set</button>
    </form>

    <!-- Create Ad -->
    <form @submit.prevent="createAd" v-if="adSetId">
      <h3>Create Ad</h3>
      <div>
        <label for="adName">Ad Name:</label>
        <input type="text" id="adName" v-model="adName" required />
      </div>
      <div>
        <label for="adContent">Ad Content:</label>
        <textarea id="adContent" v-model="adContent" required></textarea>
      </div>
      <button type="submit">Create Ad</button>
    </form>

    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      campaignName: '',
      adSetName: '',
      adName: '',
      adContent: '',
      campaignId: null,
      adSetId: null,
      errorMessage: '',
    };
  },
  methods: {
    async createCampaign() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/campaign', {
          campaignName: this.campaignName,
        });
        this.campaignId = response.data.campaign.id;
        console.log('Campaign created successfully:', response.data);
      } catch (error) {
        console.error('Error creating campaign:', error);
        this.errorMessage = 'Error creating campaign: ' + error.response?.data?.message || error.message;
      }
    },
    async createAdSet() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/adset', {
          campaignId: this.campaignId,
          adSetName: this.adSetName,
        });
        this.adSetId = response.data.adSet.id;
        console.log('Ad Set created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad set:', error);
        this.errorMessage = 'Error creating ad set: ' + error.response?.data?.message || error.message;
      }
    },
    async createAd() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad', {
          adSetId: this.adSetId,
          adName: this.adName,
          adContent: this.adContent,
        });
        console.log('Ad created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad:', error);
        this.errorMessage = 'Error creating ad: ' + error.response?.data?.message || error.message;
      }
    },
  },
};
</script>

<style scoped>
.create-instagram-ad {
  max-width: 600px;
  margin: 0 auto;
}

.create-instagram-ad form {
  margin-bottom: 30px;
}

.create-instagram-ad form div {
  margin-bottom: 15px;
}

.create-instagram-ad label {
  display: block;
  margin-bottom: 5px;
}

.create-instagram-ad input,
.create-instagram-ad textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.create-instagram-ad button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.create-instagram-ad button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 20px;
}
</style>
