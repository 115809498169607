<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
    <div class="create-instagram-ad">
      <!-- Campaign Selection --> 
      <select v-model="selectedCampaign" @change="handleCampaignChange">
          <option value="new">Create New Campaign</option>
          <option v-for="campaign in campaigns" :key="campaign.id" :value="campaign">
            {{ campaign.name }}
          </option>
        </select>

      <!-- Create Campaign -->
      <div v-if="selectedCampaign === 'new'">
        <form @submit.prevent="createCampaign"> 
          <div>
            <label for="campaignName">Campaign Name:</label>
            <input type="text" id="campaignName" v-model="campaignName" required />
          </div>
          <div>
            <label for="campaignType">Type:</label>
            <input type="text" id="campaignType" v-model="campaignType" required />
          </div>
          <div>
            <label for="spendLimit">Spend Limit:</label>
            <input type="number" id="spendLimit" v-model="spendLimit" required />
          </div>
          <button type="submit">Create Campaign</button>
        </form>
      </div>

      <!-- Display Campaign Details -->
      <div v-if="campaignId" class="campaign-details">
        <h3>Campaign Details</h3>
        <p><strong>Campaign Name:</strong> {{ campaignName }}</p>
        <p><strong>Type:</strong> {{ campaignType }}</p>
        <p><strong>Spend Limit:</strong> {{ spendLimit }}</p>
      </div>

      <!-- Create Ad Set -->
      <div v-if="campaignId">
        <form @submit.prevent="createAdSet">
          <h3>Create Ad Set</h3>
          <div>
            <label for="adSetName">Ad Set Name:</label>
            <input type="text" id="adSetName" v-model="adSetName" required />
          </div>
          <button type="submit">Create Ad Set</button>
        </form>
      </div>

      <!-- Create Ad -->
      <div v-if="adSetId">
        <form @submit.prevent="createAd">
          <h3>Create Ad</h3>
          <div>
            <label for="adName">Ad Name:</label>
            <input type="text" id="adName" v-model="adName" required />
          </div>
          <div>
            <label for="adContent">Ad Content:</label>
            <textarea id="adContent" v-model="adContent" required></textarea>
          </div>
          <button type="submit">Create Ad</button>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      campaignName: '',
      campaignType: '',
      spendLimit: '',
      adSetName: '',
      adName: '',
      adContent: '',
      campaignId: null,
      adSetId: null,
      errorMessage: '',
      campaigns: [],
      selectedCampaign: 'new'
    };
  },
  methods: {
    async fetchMetaCampaigns() {
      try {
        console.log('Fetching Meta campaigns...');
        const response = await axios.get('/admin/ad/ads/metacampaigns');
        console.log('Campaigns fetched:', response.data);
        this.campaigns = response.data;
      } catch (error) {
        this.errorMessage = 'Error fetching campaigns';
        console.error(error);
      }
    },
    handleCampaignChange() {
      if (this.selectedCampaign === 'new') {
        this.resetForm();
      } else {
        const campaign = this.selectedCampaign;
        this.campaignId = campaign.id;
        this.campaignName = campaign.name;
        this.campaignType = campaign.type;
        this.spendLimit = campaign.spend_limit;
      }
    },
    async createCampaign() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/campaign', {
          campaignName: this.campaignName,
          campaignType: this.campaignType,
          spendLimit: this.spendLimit,
        });
        this.campaignId = response.data.campaign.id;
        console.log('Campaign created successfully:', response.data);
      } catch (error) {
        console.error('Error creating campaign:', error);
        this.errorMessage = error.response?.data?.userMessage || 'An unexpected error occurred.';
      }
    },
    async createAdSet() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/adset', {
          campaignId: this.campaignId,
          adSetName: this.adSetName,
        });
        this.adSetId = response.data.adSet.id;
        console.log('Ad Set created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad set:', error);
        this.errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      }
    },
    async createAd() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad', {
          adSetId: this.adSetId,
          adName: this.adName,
          adContent: this.adContent,
        });
        console.log('Ad created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad:', error);
        this.errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      }
    },
    resetForm() {
      this.campaignName = '';
      this.campaignType = '';
      this.spendLimit = '';
      this.campaignId = null;
      this.adSetId = null;
      this.adSetName = '';
      this.adName = '';
      this.adContent = '';
      this.errorMessage = '';
    }
  },
  mounted() {
    this.fetchMetaCampaigns();
  }
};
</script>


<style scoped>
.create-instagram-ad {
  margin: 0 auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.create-instagram-ad div {
  min-width: 20rem;
}

.create-instagram-ad form {
  margin-bottom: 30px;
}

.create-instagram-ad form div {
  margin-bottom: 15px;
}

.create-instagram-ad label {
  display: block;
  margin-bottom: 5px;
}

.create-instagram-ad input,
.create-instagram-ad textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.create-instagram-ad button {
  cursor: pointer;
}

.create-instagram-ad button:hover {
  background-color: #000000;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.campaign-details {
  margin-top: 20px;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
}
</style>
