<template>
  <div>
    <h1>Products</h1>
    <ul>
      <li v-for="product in products" :key="product.id">
        {{ product.name }} - {{ product.description }} 
        (Group: {{ product.group ? product.group.name : 'N/A' }}, 
        Category: {{ product.category ? product.category.name : 'N/A' }})
        <button @click="deleteProduct(product.id)">Delete</button>
        <button @click="editProduct(product)">Edit</button>
      </li>
    </ul>
    <h2>Add Product</h2>
    <form @submit.prevent="addProduct">
      <input v-model="newProduct.name" placeholder="Name" required />
      <input v-model="newProduct.description" placeholder="Description" />
      <select v-model="newProduct.group_id" required>
        <option v-for="group in productGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
      </select>
      <select v-model="newProduct.category_id" required>
        <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
      </select>
      <button type="submit">Add</button>
    </form>
    <h2>Edit Product</h2>
    <form v-if="editingProduct.id" @submit.prevent="updateProduct">
      <input v-model="editingProduct.name" placeholder="Name" required />
      <input v-model="editingProduct.description" placeholder="Description" />
      <select v-model="editingProduct.group_id" required>
        <option v-for="group in productGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
      </select>
      <select v-model="editingProduct.category_id" required>
        <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
      </select>
      <button type="submit">Update</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      productGroups: [],
      categories: [],
      newProduct: {
        name: '',
        description: '',
        group_id: '',
        category_id: ''
      },
      editingProduct: {
        id: null,
        name: '',
        description: '',
        group_id: '',
        category_id: ''
      }
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get('/noah/protected/products'); 
        this.products = response.data.products;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    async fetchProductGroups() {
      try {
        const response = await axios.get('/noah/protected/product-groups'); 
        this.productGroups = response.data.productGroups;
      } catch (error) {
        console.error('Error fetching product groups:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/noah/protected/categories'); 
        this.categories = response.data.categories;
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async addProduct() {
      try {
        const response = await axios.post('/noah/protected/product', this.newProduct); 
        this.products.push(response.data.product);
        this.newProduct.name = '';
        this.newProduct.description = '';
        this.newProduct.group_id = '';
        this.newProduct.category_id = '';
      } catch (error) {
        console.error('Error adding product:', error);
      }
    },
    async updateProduct() {
      try {
        const response = await axios.put(`/noah/protected/product/${this.editingProduct.id}`, this.editingProduct); 
        const index = this.products.findIndex(product => product.id === this.editingProduct.id);
        this.$set(this.products, index, response.data.product);
        this.editingProduct.id = null;
        this.editingProduct.name = '';
        this.editingProduct.description = '';
        this.editingProduct.group_id = '';
        this.editingProduct.category_id = '';
      } catch (error) {
        console.error('Error updating product:', error);
      }
    },
    async deleteProduct(id) {
      try {
        await axios.delete(`/noah/protected/product/${id}`); 
        this.products = this.products.filter(product => product.id !== id);
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    },
    editProduct(product) {
      this.editingProduct.id = product.id;
      this.editingProduct.name = product.name;
      this.editingProduct.description = product.description;
      this.editingProduct.group_id = product.group_id;
      this.editingProduct.category_id = product.category_id;
    }
  },
  mounted() {
    this.fetchProducts();
    this.fetchProductGroups();
    this.fetchCategories();
  }
};
</script>
