<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>

    <div class="create-instagram-ad">
      
      <!-- Campaign Selection --> 
      <div class="campaign-selection">
        <select v-model="selectedCampaign" @change="handleCampaignChange">
            <option value="new">Create New Campaign</option>
            <option v-for="campaign in campaigns" :key="campaign.id" :value="campaign">
              {{ campaign.name }}
            </option>
        </select>

        <!-- Display Campaign Details -->
        <div v-if="selectedCampaign !== 'new' && selectedCampaign" class="campaign-details">
          <p class="title-main">Campaign Name</p>
          <h3>{{ campaignName }}</h3>
          <p><strong>Type:</strong></p>
          <img :src="getCampaignTypeImage(campaignType)" alt="Campaign Type" class="campaign-type-image" />
          <p class="title-main">Spend Limit</p>
          <h3>{{ spendLimit }}</h3>
        </div>
      </div>

      <!-- Create Campaign -->
      <div v-if="selectedCampaign === 'new'" class="create-campaign">
        <form @submit.prevent="createCampaign"> 
          <div>
            <label for="campaignName">Campaign Name:</label>
            <input type="text" id="campaignName" v-model="campaignName" required />
          </div>
          <div>
            <label>Type:</label>
            <div class="campaign-type">

              <label>
                <input type="radio" v-model="campaignType" value="interaction" required />
                <img src="./assets/message.svg" alt="Interaction" />
              </label>

              <label>
                <input type="radio" v-model="campaignType" value="traffic" required />
                <img src="./assets/click.svg" alt="Traffic" />
              </label>

            </div>
          </div>
          <div>
            <label for="spendLimit">Spend Limit:</label>
            <input type="number" id="spendLimit" v-model="spendLimit" required />
          </div>
          <button type="submit">Create Campaign</button>
        </form>
      </div>

      <!-- Ad Set Selection -->
      <div v-if="selectedCampaign !== 'new' && selectedCampaign" class="adset-selection">
        <select v-model="selectedAdSet" @change="handleAdSetChange">
          <option value="new">Create New Ad Set</option>
          <option v-for="adset in selectedCampaign.adsets" :key="adset.id" :value="adset">
            {{ adset.name }}
          </option>
        </select>

        <!-- Display Ad Set Details -->
        <div v-if="selectedAdSet !== 'new' && selectedAdSet" class="adset-details">
          <h3>Ad Set Details</h3>
          <p><strong>Ad Set Name:</strong> {{ adSetName }}</p>
        </div>
      </div>

      <!-- Create Ad Set -->
      <div v-if="selectedAdSet === 'new' && selectedCampaign !== 'new'" class="create-adset">
        <form @submit.prevent="createAdSet">
          <h3>Create Ad Set</h3>
          <div>
            <label for="adSetName">Ad Set Name:</label>
            <input type="text" id="adSetName" v-model="adSetName" required />
          </div>
          <button type="submit">Create Ad Set</button>
        </form>
      </div>

      <!-- Create Ad -->
      <div v-if="selectedAdSet !== 'new' && selectedAdSet" class="create-ad">
        <form @submit.prevent="createAd">
          <h3>Create Ad</h3>
          <div>
            <label for="adName">Ad Name:</label>
            <input type="text" id="adName" v-model="adName" required />
          </div>
          <div>
            <label for="adContent">Ad Content:</label>
            <textarea id="adContent" v-model="adContent" required></textarea>
          </div>
          <button type="submit">Create Ad</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      campaignName: '',
      campaignType: '',
      spendLimit: '',
      adSetName: '',
      adName: '',
      adContent: '',
      campaignId: null,
      adSetId: null, 
      errorMessage: '',
      campaigns: [],
      selectedCampaign: 'new',
      selectedAdSet: 'new'
    };
  },
  methods: {
    async fetchMetaCampaigns() {
      try {
        console.log('Fetching Meta campaigns...');
        const response = await axios.get('/admin/ad/ads/metacampaigns');
        console.log('Campaigns fetched:', response.data);
        this.campaigns = response.data;
      } catch (error) {
        this.errorMessage = 'Error fetching campaigns';
        console.error(error);
      }
    },
    handleCampaignChange() {
      if (this.selectedCampaign === 'new') {
        this.resetForm();
      } else {
        const campaign = this.selectedCampaign;
        this.campaignId = campaign.id;
        this.campaignName = campaign.name;
        this.campaignType = campaign.type;
        this.spendLimit = campaign.spend_limit;
        this.selectedAdSet = 'new';
      }
    },
    handleAdSetChange() {
      if (this.selectedAdSet === 'new') {
        this.adSetName = '';
        this.adSetId = null;
      } else {
        const adset = this.selectedAdSet;
        this.adSetId = adset.id;
        this.adSetName = adset.name;
      }
    },
    async createCampaign() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/campaign', {
          campaignName: this.campaignName,
          campaignType: this.campaignType,
          spendLimit: this.spendLimit,
        });
        this.campaignId = response.data.campaign.id;
        console.log('Campaign created successfully:', response.data);
        // Yeni kampanya oluşturulduğunda reklam seti oluşturma adımına geç
        this.selectedCampaign = response.data.campaign;
        this.selectedAdSet = 'new';
      } catch (error) {
        console.error('Error creating campaign:', error);
        this.errorMessage = error.response?.data?.userMessage || 'An unexpected error occurred.';
      }
    },
    async createAdSet() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad/adset', {
          campaignId: this.campaignId,
          adSetName: this.adSetName,
        });
        this.adSetId = response.data.adSet.id;
        console.log('Ad Set created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad set:', error);
        this.errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      }
    },
    async createAd() {
      this.errorMessage = '';
      try {
        const response = await axios.post('/admin/instagram-ad', {
          adSetId: this.adSetId,
          adName: this.adName,
          adContent: this.adContent,
        });
        console.log('Ad created successfully:', response.data);
      } catch (error) {
        console.error('Error creating ad:', error);
        this.errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      }
    },
    getCampaignTypeImage(type) {
  if (type === 'interaction') {
    return require('./assets/message.svg');
  } else if (type === 'traffic') {
    return require('./assets/click.svg');
  }
  return '';
},

    resetForm() {
      this.campaignName = '';
      this.campaignType = '';
      this.spendLimit = '';
      this.campaignId = null;
      this.adSetId = null;
      this.adSetName = '';
      this.adName = '';
      this.adContent = '';
      this.errorMessage = '';
    }
  },
  mounted() {
    this.fetchMetaCampaigns();
  }
};
</script>



<style scoped>
.create-instagram-ad {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
}

.campaign-selection,
.create-campaign,
.adset-selection,
.create-adset,
.adset-details,
.create-ad {
  min-width: 300px;
  flex-shrink: 0;
}
 
.create-instagram-ad div { 
  padding: 1rem;
  background: #000000;
  border-radius: 8px;
}

.create-instagram-ad h3 {
  margin-top: 0;
}

.create-instagram-ad label {
  display: block;
  margin-bottom: 5px;
}

.create-instagram-ad input,
.create-instagram-ad textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.campaign-type {
  display: flex;
  gap: 10px;
  padding: 0.1rem;
}

.campaign-type label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.campaign-type input {
  display: none;
}

.campaign-type img {
  width: 50px;
  height: 50px;
  opacity: 0.3;
  border: 1px solid #262626;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #0b0e0f;
}

.campaign-type input:checked + img {
  opacity: 1; 
  background-color: #000000;
}

.create-instagram-ad button { 
  cursor: pointer; 
}

.create-instagram-ad button:hover {
  background-color: #000000;
  color: #ffffff;
}

.error-message {
  color: red;
  margin-top: 20px;
}
.create-campaign { padding: 10px; background-color: red;}
.campaign-type-image {
  width: 50px;
  height: 50px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #000000;
  margin-bottom: 15px;
}
.title-main {
  font-size: 10px;
  margin-bottom: 4px;
  font-weight: 300;
}

</style>
