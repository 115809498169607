<template>
  <div class="login-container">
    <div class="left-panel">
      <img class="login-logo" src="../assets/login-logo.svg" /> 
      <div class="management-text">ADMINISTRATION</div>
      <img 
        class="warn-icon"
        src="../assets/icon/warn.svg" 
        alt="Warn Icon" 
        @click="openWarnModal"
      /> 
    </div>
    <div class="right-panel">
      <p class="title">Login</p>
      <form @submit.prevent="login">
        <div class="input-areas">
          <input id="email" v-model="form.email" type="email" placeholder="E-mail" required />
          <input id="password" v-model="form.password" type="password" placeholder="Password" required />
        </div>
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <button type="submit" class="submit-btn">
          <img src="../assets/icon/login-button-icon.svg" alt="Login Icon" />
        </button>
      </form>
      <div class="info-text">
        <p>Only for senior management. / Sadece üst düzey yönetim için.</p>
        <p>Please select your area / Lütfen alanınızı seçin:</p>
      </div>
      <div class="button-group">
        <button
          class="area-btn"
          v-for="area in areas"
          :key="area.name"
          :style="{ backgroundColor: area.color }"
          @click="openLink(area.url)"
        >
          {{ area.name }}
        </button>
      </div>
    </div>
    <WarnModal ref="warnModal"/>
  </div>
</template>

<script>
import axios from 'axios';
import WarnModal from './WarnModal.vue';

export default {
  components: {
    WarnModal,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      message: '',
      messageClass: 'error',
      areas: [
        { name: 'NOAH PROMO', color: '#023032', url: 'https://noahpmu.com' },
        { name: 'NOAH SHOP', color: '#106e35', url: 'https://shop.noahpmu.com' },
        { name: 'NOAH AI', color: '#650101', url: 'https://ai.noahpmu.com' },
        { name: 'REFIKA BEAUTY', color: '#065959', url: 'https://refikaguzellik.com' },
        { name: 'REFIKA BRANCH', color: '#353535', url: 'https://subeler.refikaguzellik.com' },
        { name: 'NRS', color: '#023032', url: 'https://nrs.noahpmu.com' },
      ],
    };
  },
  methods: {
    openWarnModal() {
      this.$refs.warnModal.showModal();
    },
    async login() {
      try {
        const response = await axios.post('/noah/login', this.form);
        console.log('Login successful:', response.data);
        localStorage.setItem('token', response.data.token);
        this.message = 'Login successful';
        this.messageClass = 'success';
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        this.message = 'Login failed. Please check your credentials.';
        this.messageClass = 'error';
      }
    },
    openLink(url) {
      window.open(url, '_blank');
    },
  },
};
</script>


<style scoped>
.login-container {
  display: flex;
  min-height: 85vh;
  justify-content: center;
  align-items: center; 
  flex-wrap: wrap;
}

.left-panel {
  width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
 
.warn-icon {
  max-width: 5em; margin: 2em 0 2em 0; cursor: pointer; opacity: 0.4;
}
.warn-icon:hover {
  transform: scale(1.2);
  opacity: 1;
  transition: transform 0.2s ease-in-out;
}

.login-logo {
  max-width: 100%;
  height: auto;
}
.title {
  font-size: 3em;
  font-weight: 300;
  margin: 0 0 10px 0;
  color: #b5b5b5;
}

.management-text {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 300;
  color: #353535;
  text-align: center;
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  min-width: 300px;
}

.input-areas {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.submit-btn {
  padding: 10px;
  background-color: #282828; 
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn img {
  max-width: 40px; 
  height: auto;
}

.submit-btn:hover {
  background-color: #065959;
}

.message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}

.error { 
  color: #d00000;
  margin-bottom: 1em;
}

.success { 
  color: #1ed760;
  margin-bottom: 1em;
}

.info-text {
  margin-top: 20px;
  font-size: 0.9em;
  text-align: left;
  color: #aaaaaa;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 5em;
}

.area-btn {
  flex: 1;
  padding: 10px 5px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.area-btn:hover {
  opacity: 0.8;
}

@media (max-width: 1080px) {
  .left-panel {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 0; 
    background-color: transparent;
  }
  .submit-btn { 
    align-self: flex-end;  margin-left: auto; 
  }
  .login-logo {
    max-height: 100px;
  }

  .management-text {
    font-size: 14px;
    margin-top: 10px;
  }

  .right-panel {
    width: 100%;
    padding: 10px;
  }

  .title {
    font-size: 2em;
    display: none;
  }
}
</style> 