<template>
    <div>
      <h1>Categories</h1>
      <ul>
        <li v-for="category in categories" :key="category.id">
          {{ category.name }} - {{ category.description }}
          <button @click="deleteCategory(category.id)">Delete</button>
          <button @click="editCategory(category)">Edit</button>
        </li>
      </ul>
      <h2>Add Category</h2>
      <form @submit.prevent="addCategory">
        <input v-model="newCategory.name" placeholder="Name" required />
        <input v-model="newCategory.description" placeholder="Description" />
        <button type="submit">Add</button>
      </form>
      <h2>Edit Category</h2>
      <form v-if="editingCategory.id" @submit.prevent="updateCategory">
        <input v-model="editingCategory.name" placeholder="Name" required />
        <input v-model="editingCategory.description" placeholder="Description" />
        <button type="submit">Update</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        categories: [],
        newCategory: {
          name: '',
          description: ''
        },
        editingCategory: {
          id: null,
          name: '',
          description: ''
        }
      };
    },
    methods: {
      async fetchCategories() {
        try {
          const response = await axios.get('/noah/protected/categories');
          this.categories = response.data.categories;
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      },
      async addCategory() {
        try {
          const response = await axios.post('/noah/protected/category', this.newCategory);
          this.categories.push(response.data.category);
          this.newCategory.name = '';
          this.newCategory.description = '';
        } catch (error) {
          console.error('Error adding category:', error);
        }
      },
      async updateCategory() {
        try {
          const response = await axios.put(`/noah/protected/category/${this.editingCategory.id}`, this.editingCategory);
          const index = this.categories.findIndex(category => category.id === this.editingCategory.id);
          this.$set(this.categories, index, response.data.category);
          this.editingCategory.id = null;
          this.editingCategory.name = '';
          this.editingCategory.description = '';
        } catch (error) {
          console.error('Error updating category:', error);
        }
      },
      async deleteCategory(id) {
        try {
          await axios.delete(`/noah/protected/category/${id}`);
          this.categories = this.categories.filter(category => category.id !== id);
        } catch (error) {
          console.error('Error deleting category:', error);
        }
      },
      editCategory(category) {
        this.editingCategory.id = category.id;
        this.editingCategory.name = category.name;
        this.editingCategory.description = category.description;
      }
    },
    mounted() {
      this.fetchCategories();
    }
  };
  </script>
  