<template>
  <div> 
 
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div> 
  </div>
</template>

<script>  
import CurrentAds from './CurrentAds.vue';
import CreateNew from './CreateNew.vue';
import ArchivedAds from './ArchivedAds.vue'; 
import MetaSettings from './MetaSettings.vue'; 

export default { 
  data() {
    return {
      currentComponent: 'CurrentAds',
      components: [ 
        { name: 'Ad List', component: 'CurrentAds' },
        { name: 'Create New Ad', component: 'CreateNew' },
        { name: 'Archive', component: 'ArchivedAds' }, 
        { name: 'Meta Settings', component: 'MetaSettings' }, 
      ]
    };
  },
  
  components: {
   CurrentAds, CreateNew, ArchivedAds, MetaSettings
  },
  
  methods: {
    navigateTo(routePath) {
      this.$router.push(routePath);
    }
  }
}
</script>
  
<style scoped> 
  
.button-container {
margin: 0.2em 0;
padding: 0.2rem;
text-align: center;
display: flex;
justify-content: center;
flex-wrap: wrap;  
} 
.button-container button { 
border-radius: 0.4rem;
display: inline-block;
padding: 0.5em 3em;
min-width: 6rem;
margin: 0.2em 0.5em;
border: 0.06rem solid #313131;
background-color: #111111;
color: #d3d3d3;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
opacity: 0.6;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
opacity: 1;
} 
.button-container button.active {
background-color: #064f62;
opacity: 1;
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  