<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <p class="id-title">{{ adId }}</p>
      <div v-if="adData"> 
        <p class="title" style="margin-top: 0;"><b>{{ adData.name }}</b></p>
        <p>
          <strong>Status:</strong>  
          <span :style="{ color: getStatusColor(adData.status) }">{{ adData.status }}</span>
        </p>
        <div v-if="adData.adset">
          <p><strong>AdSet Name:</strong> {{ adData.adset.name }}</p>
          <p><strong>Campaign Name:</strong> {{ adData.adset.campaign.name }}</p>
        </div> 
        <div class="actions"> 
          <p> Auto Analyse</p> 
          <p>Time period</p> 
        </div> 
        <p class="title"><b>Current statistics</b> / summary</p>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Impressions</th>
              <th>Clicks</th>
              <th>Spend</th>
              <th>CTR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="insight in adData.adinsights" :key="insight.id">
              <td>{{ insight.date }}</td>
              <td>{{ insight.impressions }}</td>
              <td>{{ insight.clicks }}</td>
              <td>{{ insight.spend }}</td>
              <td>{{ insight.ctr }}</td>
            </tr>
          </tbody>
        </table>
 

        <p class="title"><b>History</b></p>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Impressions</th>
              <th>Clicks</th>
              <th>Spend</th>
              <th>CTR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="history in adData.adhistories" :key="history.id">
              <td>{{ history.date }}</td>
              <td>{{ history.impressions }}</td>
              <td>{{ history.clicks }}</td>
              <td>{{ history.spend }}</td>
              <td>{{ history.ctr }}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <div v-else>
        <p>Loading ad data...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['adId'],
  data() {
    return {
      adData: null
    };
  },
  mounted() {
    this.fetchAdData();
  },
  methods: {
    async fetchAdData() {
      try {
        const response = await axios.get(`/admin/meta/ad/${this.adId}`);
        this.adData = response.data;
      } catch (error) {
        console.error('Error fetching ad data:', error);
      }
    },
    getStatusColor(status) {
      if (status === 'PAUSED') {
        return '#f2b200';
      } else if (status === 'ACTIVE') {
        return '#299b4f';
      } else {
        return '#ffffff'; 
      }
    }
  }
};
</script> 

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0e0f0f; 
}

.modal-content {
  background-color: #000000;
  margin: auto;
  padding: 20px;
  border: 1px solid #333333;
  border-radius: 15px;  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #383838;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000;
}
.title { 
  font-size: 14px;
  color: #28829e;
}
.id-title {
  font-size: 10px; 
  margin-bottom: 0;
}

.actions {
  background-color: #0e0f0f;
  padding: 20px;
  margin: 5px 0;
} 
</style>
