import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from './components/LoginForm.vue';
import RegisterForm from './components/RegisterForm.vue';

// NEW ADMIN STRUCTURE
import AdministrationMain from './components/administration/AdministrationMain.vue';


// MAIN ROUTES  
import AdminNoahMain from './components/administration/NOAH/NoahAdmin.vue';
import AdminShopMain from './components/administration/SHOP/ShopAdmin.vue';
import AdminRefikaMain from './components/administration/REFIKA/RefikaAdmin.vue';
import AdminAdMain from './components/administration/AD/AdAdmin.vue';
import AdminBetaMain from './components/administration/BETA/BetaAdmin.vue';
import AdminTestMain from './components/administration/TEST/TestAdmin.vue';


const routes = [ 

    { path: '/', component: LoginForm },
    { path: '/register', component: RegisterForm }, 

    //NEW STRUCTURE
    { path: '/administration', component: AdministrationMain },

    // MAIN ROUTES  
    { path: '/administration/noah', component: AdminNoahMain },
    { path: '/administration/shop', component: AdminShopMain },
    { path: '/administration/refika', component: AdminRefikaMain },
    { path: '/administration/ad', component: AdminAdMain },
    { path: '/administration/beta', component: AdminBetaMain },
    { path: '/administration/test', component: AdminTestMain }, 
 
];
 
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (!token && to.path !== '/') {
    next('/');
  } else {
    next();
  }
});
export default router; 