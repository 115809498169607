<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">Close</button>
      <div class="modal-body">
        <h2>Yasal Uyarı ve Kullanım Koşulları</h2>
        <p><strong>1. Giriş</strong></p>
        <p>
          Bu web sitesine  <b>(administration.noahpmu.com bundan böyle "Site" olarak anılacaktır)</b> erişim sağlayarak, aşağıda belirtilen kullanım koşullarını ve hukuki beyanları kabul etmiş sayılırsınız. Bu koşulları kabul etmiyorsanız, Site’yi kullanmaya devam etmeyiniz. Noah PMU, bu koşulları herhangi bir bildirimde bulunmaksızın değiştirme hakkını saklı tutar. Bu sebeple, Site’ye her erişim sağladığınızda bu koşulları gözden geçirmeniz önemlidir.
        </p>
        <p><strong>2. Telif Hakkı ve Fikri Mülkiyet Hakları</strong></p>
        <p>
          Site’de yer alan tüm içerik, metinler, grafikler, logolar, ikonlar, görseller, ses klipleri, dijital indirmeler, veri derlemeleri ve yazılım, Noah PMU’nun <b>(bundan böyle "Şirket" olarak anılacaktır)</b> mülkiyetinde olup, ulusal ve uluslararası telif hakkı yasaları tarafından korunmaktadır. Bu materyallerin izinsiz kullanımı, kopyalanması, çoğaltılması, dağıtılması, yayınlanması, değiştirilmesi veya herhangi bir ticari amaçla kullanılması yasaktır. Şirket, telif hakkı ihlalleri durumunda hukuki işlem başlatma hakkını saklı tutar.
        </p>
        <p><strong>3. Kullanıcı Yükümlülükleri</strong></p>
        <p>
          Site’ye erişim sağlayan her kullanıcı, yalnızca yasal amaçlarla Site’yi kullanmayı kabul eder. Kullanıcı, Site üzerinden ilettiği, yüklediği veya erişim sağladığı tüm bilgilerin doğruluğundan, gizliliğinden ve hukuka uygunluğundan sorumludur. Herhangi bir şekilde yasa dışı, yanıltıcı, zararlı, tehdit edici, hakaret içeren, iftira niteliğinde, müstehcen, rahatsız edici veya telif hakkı, marka, gizlilik veya diğer kişisel hakları ihlal eden içerikler Site’ye yüklenemez veya Site üzerinden iletilemez.
        </p>
        <p><strong>4. Erişim ve Kullanım Denetimi</strong></p>
        <p>
          SİTE’YE YAPILAN TÜM ERİŞİMLER KAYIT ALTINA ALINMAKTADIR. YETKİSİZ ERİŞİM GİRİŞİMLERİ VE KÖTÜ NİYETLİ KULLANIM DENEMELERİ, ŞİRKET TARAFINDAN TAKİP EDİLMEKTE VE HUKUKİ İŞLEM BAŞLATILMAKTADIR. SİTE’YE YETKİSİZ ERİŞİM SAĞLAMA GİRİŞİMLERİ, VERİ TABANINA MÜDAHALE ETME, SİTE’NİN İŞLEYİŞİNİ BOZMA VEYA ŞİRKET’İN TİCARİ SIRLARINI İFŞA ETME GİBİ EYLEMLER, HUKUKİ YAPTIRIMLARA TABİDİR. ŞİRKET, BU TÜR EYLEMLERE KARŞI YASAL HAKLARINI KULLANMA HAKKINI SAKLI TUTAR.
        </p>
        <p><strong>5. Hukuki Yaptırımlar</strong></p>
        <p>
          Site’de yer alan koşulları ihlal eden, telif haklarını çiğneyen veya yetkisiz erişim girişimlerinde bulunan kişiler, ulusal ve uluslararası yasalar kapsamında cezai ve hukuki yaptırımlarla karşı karşıya kalacaktır. Şirket, telif hakkı ihlallerine ve yetkisiz erişimlere karşı maddi ve manevi tazminat talepleri, dava açma ve adli mercilere başvurma hakkını saklı tutar.
        </p>
        <p><strong>6. Sorumluluğun Sınırlandırılması</strong></p>
        <p>
          Site’nin kullanımı ile ilgili olarak doğrudan veya dolaylı olarak ortaya çıkan zararlar, kayıplar, veri kayıpları veya kâr kayıplarından Şirket sorumlu tutulamaz. Kullanıcılar, Site’yi kullanarak, Şirket’in bu tür zararlardan dolayı sorumlu olmadığını kabul ederler.
        </p>
        <p><strong>7. Üçüncü Taraf Bağlantıları</strong></p>
        <p>
          Site’de yer alan bağlantılar, üçüncü taraf sitelere yönlendirebilir. Şirket, bu sitelerin içeriklerinden veya gizlilik politikalarından sorumlu değildir. Kullanıcılar, bu tür sitelere erişim sağladıklarında, o sitelerin kullanım koşullarını kabul etmiş sayılırlar.
        </p>
        <p><strong>8. Veri Koruma ve Gizlilik</strong></p>
        <p>
          Kullanıcıların Site’ye sağladıkları kişisel veriler, Şirket’in gizlilik politikası doğrultusunda işlenir ve korunur. Kullanıcılar, kişisel verilerinin toplanmasına, işlenmesine ve gerektiğinde yetkili mercilere iletilmesine onay vermektedir.
        </p>
        <p><strong>9. Uyuşmazlıkların Çözümü</strong></p>
        <p>
          Bu kullanım koşulları ve Site’nin kullanımı ile ilgili ortaya çıkabilecek her türlü uyuşmazlıkta, İstanbul mahkemeleri ve icra daireleri yetkili olacaktır. Uyuşmazlık durumunda Türk hukuku uygulanacaktır.
        </p>
        <p><strong>10. Son Hükümler</strong></p>
        <p>
          Bu metinde yer almayan hususlar için genel hukuki kurallar geçerli olacaktır. Kullanıcılar, Site’ye erişim sağlayarak bu koşulları eksiksiz olarak okuduklarını, anladıklarını ve kabul ettiklerini beyan ederler.
        </p>
        <p>Noah PMU, bu kullanım koşullarını zaman zaman güncelleme hakkını saklı tutar. Kullanıcılar, bu koşulları düzenli olarak kontrol etmekle yükümlüdür.</p>
        <p><strong>Yürürlük tarihi:</strong> 01.08.2024 00:01am UTC</p>
        
        <hr>

        <h2>Legal Notice and Terms of Use</h2>
        <p><strong>1. Introduction</strong></p>
        <p>
          By accessing this website (hereinafter referred to as the "Site"), you agree to be bound by the following terms of use and legal notices. If you do not accept these terms, please do not continue using the Site. Noah PMU reserves the right to modify these terms at any time without prior notice. Therefore, it is important to review these terms each time you access the Site.
        </p>
        <p><strong>2. Copyright and Intellectual Property Rights</strong></p>
        <p>
          All content, texts, graphics, logos, icons, images, sound clips, digital downloads, data compilations, and software available on the Site are the property of Noah PMU (hereinafter referred to as the "Company") and are protected by national and international copyright laws. Unauthorized use, copying, reproduction, distribution, publication, modification, or any commercial use of these materials is prohibited. The Company reserves the right to take legal action in case of copyright infringement.
        </p>
        <p><strong>3. User Obligations</strong></p>
        <p>
          Every user accessing the Site agrees to use the Site solely for lawful purposes. The user is responsible for the accuracy, confidentiality, and legality of all information they transmit, upload, or access through the Site. Content that is illegal, misleading, harmful, threatening, defamatory, obscene, harassing, or that infringes upon copyright, trademark, privacy, or other personal rights may not be uploaded to or transmitted through the Site.
        </p>
        <p><strong>4. Access and Usage Monitoring</strong></p>
        <p>
          ALL ACCESS TO THE SITE IS RECORDED. UNAUTHORIZED ACCESS ATTEMPTS AND MALICIOUS USE ATTEMPTS ARE MONITORED BY THE COMPANY AND LEGAL ACTION WILL BE INITIATED. ATTEMPTS TO GAIN UNAUTHORIZED ACCESS TO THE SITE, INTERFERE WITH THE DATABASE, DISRUPT THE SITE'S OPERATION, OR DISCLOSE THE COMPANY’S TRADE SECRETS ARE SUBJECT TO LEGAL SANCTIONS. THE COMPANY RESERVES THE RIGHT TO EXERCISE ITS LEGAL RIGHTS AGAINST SUCH ACTIONS.
        </p>
        <p><strong>5. Legal Consequences</strong></p>
        <p>
          Individuals who violate the terms of the Site, infringe copyright, or make unauthorized access attempts will be subject to criminal and legal penalties under national and international laws. The Company reserves the right to seek financial and moral compensation, file lawsuits, and apply to judicial authorities for copyright infringements and unauthorized access.
        </p>
        <p><strong>6. Limitation of Liability</strong></p>
        <p>
          The Company shall not be liable for any direct or indirect damages, losses, data loss, or loss of profits arising from the use of the Site. Users accept that the Company is not responsible for such damages by using the Site.
        </p>
        <p><strong>7. Third-Party Links</strong></p>
        <p>
          The Site may contain links to third-party websites. The Company is not responsible for the content or privacy policies of these sites. Users accept the terms of use of these sites when accessing such links.
        </p>
        <p><strong>8. Data Protection and Privacy</strong></p>
        <p>
          Personal data provided by users to the Site are processed and protected in accordance with the Company's privacy policy. Users consent to the collection, processing, and transfer of their personal data to authorized authorities if necessary.
        </p>
        <p><strong>9. Dispute Resolution</strong></p>
        <p>
          Any disputes arising from these terms and the use of the Site shall be resolved under the jurisdiction of the courts and execution offices of Istanbul. Turkish law shall apply in case of disputes.
        </p>
        <p><strong>10. Final Provisions</strong></p>
        <p>
          General legal rules shall apply to issues not covered in this document. Users acknowledge that they have fully read, understood, and accepted these terms by accessing the Site.
        </p>
        <p>Noah PMU reserves the right to update these terms from time to time. Users are required to regularly check these terms.</p>
        <p><strong>Effective Date:</strong> 01.08.2024 00:01am UTC</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    showModal() {
      this.isVisible = true;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgb(0, 0, 0);
  padding: 3em;
  border-radius: 10px;
  border: solid 1px #2c0000;
  width: 80%;
  max-width: 600px;
  text-align: left;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #9b0101;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-body {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 70vh;
}

.close-btn:hover {
  background-color: #d00000;
}
</style>
