<template>
  <div class="main-container">
    <div class="container">   
      <div class="head-buttons"> 
        <button class="sync-button" @click="syncCampaigns"><img :src="syncIcon" class="button-icon"/> Sync Campaigns</button>
        <button class="sync-button" @click="syncAdSets"><img :src="syncIcon" class="button-icon"/> Sync Ad Sets</button>
        <button class="sync-button" @click="syncAds"><img :src="syncIcon" class="button-icon"/> Sync Ads</button>
        <button class="sync-button" @click="syncAll"><img :src="syncIcon" class="button-icon"/> Sync All</button> 
      </div>   
    </div>

    <div class="head-buttons"> 
        <button class="debug-button" @click="getTokenDetails"><img :src="syncIcon" class="button-icon"/> Debug Token</button> 
        <button class="extend-button" @click="extendAccessToken"><img :src="syncIcon" class="button-icon"/> Extend Access Token</button>
     </div> 

     <div v-if="tokenDetails" class="tokendetails">
      <h4>Token Details</h4>
      <p>App ID: {{ tokenDetails.app_id }}</p>
      <p>Type: {{ tokenDetails.type }}</p>
      <p>Expires At: {{ new Date(tokenDetails.expires_at * 1000).toLocaleString() }}</p>
      <p>Is Valid: {{ tokenDetails.is_valid }}</p>
    </div>

    <div class="meta-info">
      <h2 style="color: #007999;">Meta API Settings</h2>
      <form @submit.prevent="saveMetaPlatform">
        <div>
          <label for="platform_name">Platform Name:</label>
          <input v-model="metaPlatform.platform_name" id="platform_name" type="text" disabled />
        </div>
        <div>
          <label for="link">API Link:</label>
          <input v-model="metaPlatform.link" id="link" type="text" />
        </div>
        <div>
          <label for="api_key">API Key:</label>
          <input v-model="metaPlatform.api_key" id="api_key" type="text" />
        </div>
        <div>
          <label for="secret_key">Secret Key:</label>
          <input v-model="metaPlatform.secret_key" id="secret_key" type="text" />
        </div>
        <div>
          <label for="refresh_token">Refresh Token:</label>
          <input v-model="metaPlatform.refresh_token" id="refresh_token" type="text" />
        </div>
        <div>
          <label for="access_token">Access Token:</label>
          <input v-model="metaPlatform.access_token" id="access_token" type="text" />
        </div>
        <div>
          <label for="client_id">Client ID:</label>
          <input v-model="metaPlatform.client_id" id="client_id" type="text" />
        </div>
        <div>
          <label for="client_secret">Client Secret:</label>
          <input v-model="metaPlatform.client_secret" id="client_secret" type="text" />
        </div>
        <div>
          <label for="is_active">Is Active:</label>
          <select v-model="metaPlatform.is_active" id="is_active">
            <option :value="true">Active</option>
            <option :value="false">Inactive</option>
          </select>
        </div>
        <button type="submit">Save</button>
      </form>
    </div> 
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      campaigns: [],
      metaPlatform: {
        platform_id: null,
        platform_name: '',
        link: '',
        api_key: '',
        secret_key: '',
        refresh_token: '',
        access_token: '',
        client_id: '',
        client_secret: '',
        is_active: false
      },
      loading: true,
      error: null,
      homeIcon: require('../../assets/icons/home.svg'),
      syncIcon: require('../../assets/icons/sync.svg'),
      tokenDetails: null // Token details state
    };
  },
  methods: { 
    async fetchMetaPlatform() {
      try {
        console.log('Fetching Meta platform info...');
        const response = await axios.get('/admin/platforms/meta/details');
        console.log('Meta platform info:', response.data);
        this.metaPlatform = response.data;
      } catch (error) {
        this.error = 'Error fetching Meta platform info';
        console.error('Error fetching Meta platform info:', error);
      }
    },
    async saveMetaPlatform() {
      try {
        await axios.put(`/admin/platforms/${this.metaPlatform.platform_id}`, this.metaPlatform);
        this.fetchMetaPlatform();
      } catch (error) {
        this.error = 'Error saving Meta platform info';
        console.error(error);
      }
    },
    async syncCampaigns() {
      try {
        await axios.get('/admin/sync-campaigns'); 
      } catch (error) {
        this.error = 'Error syncing campaigns';
        console.error(error);
      }
    },
    async syncAdSets() {
      try {
        await axios.get('/admin/sync-adsets'); 
      } catch (error) {
        this.error = 'Error syncing ad sets';
        console.error(error);
      }
    },
    async syncAds() {
      try {
        await axios.get('/admin/sync-ads'); 
      } catch (error) {
        this.error = 'Error syncing ads';
        console.error(error);
      }
    },
    async syncAll() {
      try {
        await Promise.all([
          axios.get('/admin/sync-campaigns'),
          axios.get('/admin/sync-adsets'),
          axios.get('/admin/sync-ads')
        ]); 
      } catch (error) {
        this.error = 'Error syncing all data';
        console.error(error);
      }
    },
    async extendAccessToken() {
      try {
        await axios.post('/admin/platforms/meta/extend-token');
        this.fetchMetaPlatform();
      } catch (error) {
        this.error = 'Error extending access token';
        console.error(error);
      }
    },
    async getTokenDetails() {
      try {
        const response = await axios.get('/admin/platforms/meta/debug-token');
        this.tokenDetails = response.data;
      } catch (error) {
        this.error = 'Error fetching token details';
        console.error(error);
      }
    },
    navigateTo(routePath) {
      this.$router.push(routePath);
    },
    navigateToAdDashboard(adId) {
      this.$router.push({ path: `/ad/ads/addashboard/${adId}` });
    }
  },
  mounted() { 
    this.fetchMetaPlatform();
  },
};
</script>

  
  <style scoped>
  .head-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    gap: 5px;
  }
  .adhome-button {
    background-color: #917e5c;
  }
  .adhome-button:hover {
    background-color: #5d523d;
  }
  .home-button {
    background-color: #9b294f; 
  }
  .home-button:hover {
    background-color: #881f42;
  }
  .sync-button {
    background-color: #303030;
    font-size: 10px;
    font-weight: 300;
    color: white;
  }
  .sync-button:hover {
    background-color: #114d36;
  } 
  .extend-button {
    background-color: #064f62;
    font-size: 10px;
    font-weight: 300;
    color: white;
  }
  .extend-button:hover {
    background-color: #007999;
  } 
  .debug-button {
    background-color: #5d523d;
    font-size: 10px;
    font-weight: 300;
    color: white;
  }
  .debug-button:hover {
    background-color: #917e5c;
  } 
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .meta-info  {
    width: 100%;
    max-width: 1280px;
    margin-bottom: 50px;
  }

  .meta-info form button {
    align-self: flex-start;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .meta-info form button:hover {
    background-color: #218838;
  }

 
  .tokendetails {
    background-color: #5d523d;
    padding: 20px;
    border-radius: 0.5em;
    width: 100%;
    max-width: 680px;
  }  

  form > div { margin: 1em 0;}
  form label { color: #09afd6;}
</style>
  