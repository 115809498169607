<template>
  <div class="register-form">
    <h1>Register</h1>
    <form @submit.prevent="register">
      <div>
        <label for="name">Name:</label>
        <input id="name" v-model="form.name" type="text" required>
      </div>
      <div>
        <label for="surname">Surname:</label>
        <input id="surname" v-model="form.surname" type="text" required>
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" v-model="form.email" type="email" required>
      </div>
      <div>
        <label for="phone">Phone:</label>
        <input id="phone" v-model="form.phone" type="text">
      </div>
      <div>
        <label for="address">Address:</label>
        <input id="address" v-model="form.address" type="text">
      </div>
      <div>
        <label for="age">Age:</label>
        <input id="age" v-model="form.age" type="number">
      </div>
      <div>
        <label for="password">Password:</label>
        <input id="password" v-model="form.password" type="password" required>
      </div>
      <div>
        <button type="submit">Register</button>
      </div>
    </form>
    <div v-if="message" :class="messageClass">{{ message }}</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        age: '',
        password: ''
      },
      message: '',
      messageClass: 'error'
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post('/auth/register', this.form);
        this.message = response.data.message;
        this.messageClass = 'success';
        console.log('Registration successful:', response.data);
      } catch (error) {
        this.message = error.response?.data.message || 'An unknown error occurred';
        this.messageClass = 'error';
        console.error('Registration failed:', error.response || error.message);
      }
    }
  }
};
</script>

<style scoped>
.register-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}
button {
  width: 100%;
  padding: 8px;
  background-color: #42b983;
  border: none;
  color: white;
  cursor: pointer;
}
button:hover {
  opacity: 0.8;
}
.success {
  color: green;
}
.error {
  color: red;
}
.message {
  margin-top: 10px;
}
</style>
