<template>
  <div id="app"> 
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" class="router-view"></component>
        </transition>
      </router-view>
    </div>
    <MainFooter />
  </div>
</template>

<script> 
import MainFooter from './components/MainFooter.vue'; 
import router from './router'; 

export default {
  name: 'App',
  components: { 
    MainFooter, 
  },
  mounted() { 
    setInterval(() => {
      const token = localStorage.getItem('token');
      if (!token && router.currentRoute.path !== '/') {
        router.push('/');
      }
    }, 1000); 
  }
}
</script>
