<template>
  <div> 
    <MetaAds />
  </div>
</template>

<script>
import MetaAds from '@/components/administration/AD/meta/meta/MetaAds.vue';

export default {
  components: {
    MetaAds
  }
}
</script>

<style scoped>
</style>
