<template>
    <div>
      <h1>Product Groups</h1>
      <ul>
        <li v-for="group in productGroups" :key="group.id">
          {{ group.name }} - {{ group.description }}
          <button @click="deleteProductGroup(group.id)">Delete</button>
          <button @click="editProductGroup(group)">Edit</button>
        </li>
      </ul>
      <h2>Add Product Group</h2>
      <form @submit.prevent="addProductGroup">
        <input v-model="newGroup.name" placeholder="Name" required />
        <input v-model="newGroup.description" placeholder="Description" />
        <button type="submit">Add</button>
      </form>
      <h2>Edit Product Group</h2>
      <form v-if="editingGroup.id" @submit.prevent="updateProductGroup">
        <input v-model="editingGroup.name" placeholder="Name" required />
        <input v-model="editingGroup.description" placeholder="Description" />
        <button type="submit">Update</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        productGroups: [],
        newGroup: {
          name: '',
          description: ''
        },
        editingGroup: {
          id: null,
          name: '',
          description: ''
        }
      };
    },
    methods: {
      async fetchProductGroups() {
        try {
          const response = await axios.get('/noah/protected/product-groups');
          this.productGroups = response.data.productGroups;
        } catch (error) {
          console.error('Error fetching product groups:', error);
        }
      },
      async addProductGroup() {
        try {
          const response = await axios.post('/noah/protected/product-group', this.newGroup);
          this.productGroups.push(response.data.productGroup);
          this.newGroup.name = '';
          this.newGroup.description = '';
        } catch (error) {
          console.error('Error adding product group:', error);
        }
      },
      async updateProductGroup() {
        try {
          const response = await axios.put(`/noah/protected/product-group/${this.editingGroup.id}`, this.editingGroup);
          const index = this.productGroups.findIndex(group => group.id === this.editingGroup.id);
          this.$set(this.productGroups, index, response.data.productGroup);
          this.editingGroup.id = null;
          this.editingGroup.name = '';
          this.editingGroup.description = '';
        } catch (error) {
          console.error('Error updating product group:', error);
        }
      },
      async deleteProductGroup(id) {
        try {
          await axios.delete(`/noah/protected/product-group/${id}`);
          this.productGroups = this.productGroups.filter(group => group.id !== id);
        } catch (error) {
          console.error('Error deleting product group:', error);
        }
      },
      editProductGroup(group) {
        this.editingGroup.id = group.id;
        this.editingGroup.name = group.name;
        this.editingGroup.description = group.description;
      }
    },
    mounted() {
      this.fetchProductGroups();
    }
  };
  </script>
  