<template>
    <div>
      <h2>Static Pages</h2>
      <ul>
        <li v-for="page in staticPages" :key="page.id">
          <strong>{{ page.title }}</strong> - {{ page.slug }}
          <button @click="deletePage(page.id)">Delete</button>
          <button @click="editPage(page)">Edit</button>
        </li>
      </ul>
      
      <h3>Add Static Page</h3>
      <form @submit.prevent="addPage">
        <input v-model="newPage.title" placeholder="Title" required />
        <input v-model="newPage.slug" placeholder="Slug" required />
        <textarea v-model="newPage.content" placeholder="Content" required></textarea>
        <button type="submit">Add</button>
      </form>
      
      <h3>Edit Static Page</h3>
      <form v-if="editingPage.id" @submit.prevent="updatePage">
        <input v-model="editingPage.title" placeholder="Title" required />
        <input v-model="editingPage.slug" placeholder="Slug" required />
        <textarea v-model="editingPage.content" placeholder="Content" required></textarea>
        <button type="submit">Update</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        staticPages: [],
        newPage: {
          title: '',
          slug: '',
          content: ''
        },
        editingPage: {
          id: null,
          title: '',
          slug: '',
          content: ''
        }
      };
    },
    methods: {
      async fetchPages() {
        try {
          const response = await axios.get('/noah/staticpages');
          this.staticPages = response.data.staticPages;
        } catch (error) {
          console.error('Error fetching static pages:', error);
        }
      },
      async addPage() {
        try {
          const response = await axios.post('/noah/staticpage', this.newPage);
          this.staticPages.push(response.data.staticPage);
          this.newPage.title = '';
          this.newPage.slug = '';
          this.newPage.content = '';
        } catch (error) {
          console.error('Error adding static page:', error);
        }
      },
      async updatePage() {
        try {
          const response = await axios.put(`/noah/protected/staticpages/${this.editingPage.id}`, this.editingPage);
          const index = this.staticPages.findIndex(page => page.id === this.editingPage.id);
          this.$set(this.staticPages, index, response.data.staticPage);
          this.editingPage.id = null;
          this.editingPage.title = '';
          this.editingPage.slug = '';
          this.editingPage.content = '';
        } catch (error) {
          console.error('Error updating static page:', error);
        }
      },
      async deletePage(id) {
        try {
          await axios.delete(`/noah/protected/staticpages/${id}`);
          this.staticPages = this.staticPages.filter(page => page.id !== id);
        } catch (error) {
          console.error('Error deleting static page:', error);
        }
      },
      editPage(page) {
        this.editingPage.id = page.id;
        this.editingPage.title = page.title;
        this.editingPage.slug = page.slug;
        this.editingPage.content = page.content;
      }
    },
    mounted() {
      this.fetchPages();
    }
  };
  </script>
  